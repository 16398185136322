import { TeambookReactSelect } from "../../../../default_components/TeambookReactSelect";
import BlueArrowDown from "../../../../default_images/darkblue_arrow_down.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";

const TimeOffSyncTab = ({
  timeOffTypes,
  teambookTimeOffs,
  setTeambookTimeOffIds,
  teambookTimeOffIds,
  open,
  integration,
  changePersonioIntegration,
}) => {
  const { t } = useTranslation();

  const ignoredTimeOff = { label: t("settings.integration.personio.ignore_time_off"), value: null };

  if (!open) return null;

  return (
    <div className="personio__sync-setting">
      <div className="personio__main-setting">
        <p>{t("settings.integration.personio.sync_time_offs")}</p>
        <Switch
          checked={integration?.time_off_sync}
          onChange={(event) => {
            if (event.target.checked) {
              changePersonioIntegration("time_off_sync", true);
            } else {
              changePersonioIntegration("time_off_sync", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="personio__description">{t("settings.integration.personio.sync_time_offs_description")}</p>

      <div className="personio__sub-setting">
        <p>{t("settings.integration.personio.sync_time_off_comments")}</p>
        <Switch
          checked={integration?.pull_time_off_comments}
          onChange={(event) => {
            if (event.target.checked) {
              changePersonioIntegration("pull_time_off_comments", true);
            } else {
              changePersonioIntegration("pull_time_off_comments", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="personio__title">{t("settings.integration.personio.sync_time_offs_configure")}:</p>

      <p className="personio__description">{t("settings.integration.personio.sync_time_offs_configure_description")}</p>

      {timeOffTypes.length === 0 && (
        <p className="personio__no-time-off-types">{t("settings.integration.personio.sync_time_offs_no_types")}</p>
      )}

      {timeOffTypes.map((type, index) => (
        <div className="personio__time-off" key={`type-${index}`}>
          <p>{type.name}</p>

          <TeambookReactSelect
            height={40}
            width={200}
            onChange={(option) => {
              let array = [...teambookTimeOffIds];
              array[index] = option.value;
              setTeambookTimeOffIds(array);
            }}
            value={
              teambookTimeOffs
                .map((timeOff) => ({
                  label: timeOff.name,
                  value: timeOff.id,
                }))
                .find((element) => element.value === teambookTimeOffIds[index]) || ignoredTimeOff
            }
            options={[
              ...teambookTimeOffs.map((timeOff) => ({
                label: timeOff.name,
                value: timeOff.id,
              })),
              ignoredTimeOff,
            ]}
            dropdownArrow={BlueArrowDown}
          />
        </div>
      ))}
    </div>
  );
};

export default TimeOffSyncTab;
