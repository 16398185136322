import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import XLSExport from "../../../lib/XLSExport";
import LoadingComponent from "../../default_components/LoadingComponent";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const UploadActualsDialog = ({ setUploadLogsOpen, updateActuals }) => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState("");
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const uploadLogs = () => {
    setLoading(true);

    Api.ActualLogs.upload({
      logs_string: logs,
    }).then((response) => {
      setLoading(false);
      setErrors();

      if (response.data.errors.length > 0) {
        setLogs("");
        setErrors(response.data.errors);
      } else {
        updateActuals();
        closeModal();
      }
    });
  };

  const closeModal = () => {
    setUploadLogsOpen(false);
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        closeModal();
      }}
      className="tb-default-dialog"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("actuals.upload_logs")}</p>
        <TeambookIcon name={icons.CROSS} onClick={() => closeModal()} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <p>{t("actuals.upload_text_1")}</p>

        <p className="actuals__upload-link-text" onClick={XLSExport.exportUploadingActualsFile}>
          File
        </p>

        <p>{t("actuals.upload_text_2")}</p>

        <p>{t("actuals.upload_text_3")}</p>

        <TextField
          id="outlined-multiline-static"
          className="text-field actuals-text-field tb__bg__white full-width"
          label={t("header.actuals")}
          placeholder={"jim.h@teambookapp.com\tProject Name\t4\n"}
          multiline
          rows="4"
          variant="outlined"
          onChange={(event) => setLogs(event.target.value)}
          value={logs}
        />

        {errors && (
          <div className="danger-text">
            <p>Some records were not uploaded:</p>
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        )}

        {loading && <LoadingComponent />}
      </DialogContent>
      <DialogActions style={{ gap: 20 }}>
        <TeambookOutlinedButton
          onClick={() => {
            closeModal();
          }}
          text={t("cancel")}
        />
        <TeambookBlueButton text={t("upload")} disabled={loading} onClick={uploadLogs} />
      </DialogActions>
    </Dialog>
  );
};

export default UploadActualsDialog;
