export const statusOptions = (t) => {
  return statuses(t).map((status) => ({
    value: status.value,
    name: status.label,
  }));
};

export const statuses = (t) => {
  return [
    {
      label: t("projects.to_be_confirmed"),
      value: "to_be_confirmed",
    },
    {
      label: t("projects.on_going"),
      value: "on_going",
    },
    {
      label: t("projects.done"),
      value: "done",
    },
  ];
};
