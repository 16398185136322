import React from "react";
import Button from "@mui/material/Button";
import "./default_styles.scss";

const TeambookBlueButton = ({
  htmlText,
  text,
  onClick,
  style = {},
  className,
  id,
  disabled,
  textId,
  disabledAnimations,
  size = "medium",
}) => {
  const buttonSizes = {
    small: { minHeight: "32px", fontSize: "12px", padding: "4px 8px" },
    medium: { minHeight: "40px", fontSize: "14px", padding: "8px 14px" },
  };

  return (
    <Button
      disabled={disabled}
      className={`teambook-blue-button  ${className} ${disabledAnimations ? "no-animations" : ""}`}
      onClick={onClick}
      style={{
        ...buttonSizes[size],
        ...style,
      }}
      id={id}
    >
      {htmlText && <>{htmlText}</>}
      {text && <p id={textId ? textId : ""}>{text}</p>}
    </Button>
  );
};

export default TeambookBlueButton;
