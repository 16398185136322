import betaImage from "../../../default_images/beta.png";
import { Switch } from "@mui/material";
import TeambookWhiteButton from "../../TeambookWhiteButton";
import Api from "../../../../Api";
import TeambookBlueButton from "../../TeambookBlueButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const GoogleTab = ({ open }) => {
  const { t } = useTranslation();

  const [googleIntegration, setGoogleIntegration] = useState();

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  useEffect(() => {
    fetchGoogleCalendar();
  }, []);

  const successGoogle = (codeResponse) => {
    Api.Integrations.Google.createCalendar(codeResponse.code).then(() => {
      fetchGoogleCalendar();
    });
  };

  const errorGoogle = (response) => {
    console.log(response);
  };

  const fetchGoogleCalendar = () => {
    Api.Integrations.Google.fetchCalendar().then((res) => {
      setGoogleIntegration(res.data);
    });
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => successGoogle(codeResponse),
    onError: errorGoogle,
    scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
    access_type: "offline",
    prompt: "consent",
    flow: "auth-code",
  });

  const changeGoogleIntegration = (type, value) => {
    Api.Integrations.Google.updateSettings({ [type]: value }).then(() => {
      fetchGoogleCalendar();

      setInAppNotification("Changes were saved!");
    });
  };

  if (!open) {
    return null;
  }

  if (!googleIntegration) {
    return (
      <div className="calendar-integration deactivated">
        <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
        <TeambookBlueButton text={"Connect to Google"} onClick={loginGoogle} />
      </div>
    );
  }

  return (
    <div className="calendar-integration">
      <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
      <div className="google-integration__row">
        <p>{t("settings.integration.google_calendar.push")}</p>
        <Switch
          id={"integrateGooglePush"}
          checked={googleIntegration.events_push}
          onChange={(event) => {
            if (event.target.checked) {
              changeGoogleIntegration("events_push", true);
            } else {
              changeGoogleIntegration("events_push", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">{t("settings.integration.google_calendar.push_description")}</p>

      <div className="google-integration__row">
        <p title={t("settings.integration.google_calendar.pull_details")}>
          {t("settings.integration.google_calendar.pull")}
        </p>
        <Switch
          id={"integrateGooglePull"}
          checked={googleIntegration.events_pull}
          onChange={(event) => {
            if (event.target.checked) {
              changeGoogleIntegration("events_pull", true);
            } else {
              changeGoogleIntegration("events_pull", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">
        {t("settings.integration.google_calendar.pull_description_1")} <br />{" "}
        {t("settings.integration.google_calendar.pull_description_2")}
      </p>

      <div className="google-integration__row__secondary">
        <p>{t("settings.integration.google_calendar.pull_time_offs")}</p>
        <Switch
          id={"integrateGooglePullTimeOffs"}
          checked={googleIntegration.pull_time_offs}
          onChange={(event) => {
            if (event.target.checked) {
              changeGoogleIntegration("pull_time_offs", true);
            } else {
              changeGoogleIntegration("pull_time_offs", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <TeambookWhiteButton
        text={t("disconnect")}
        onClick={() => {
          confirm(t("settings.integration.google_calendar.delete")) &&
            Api.Integrations.Google.deleteCalendar().then(() => {
              fetchGoogleCalendar();
            });
        }}
        className="disconnect-button"
      />
    </div>
  );
};

export default GoogleTab;
