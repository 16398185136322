import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { OffsetDays, GetDaysInZoom, getCellWidthForZoom } from "../../../../lib/DateUtils";
import UserRowWeek from "./UserRowWeek";
import UserBookings from "../../default_components/UserBookings";
import UserHoursLeft from "../../default_components/UserHoursLeft";
import DayInfo from "../../default_components/DayInfo";
import UserBlockedSpace from "../../default_components/UserBlockedSpace";
import { PlannerContext } from "../../context/PlannerContext";
import { useDrop } from "react-dnd";
import $ from "jquery";
import { Dropzone } from "../../default_components/Dropzone";
import {
  useBookingsCreatingStore,
  useBookingsStore,
  useCopyBookingsStore,
  useDateStore,
  useZoomStore,
} from "../../../../stores/planner";
import DayViewRow from "./DayViewRow";
import DayViewCreation from "./DayViewCreation";
import { shallow } from "zustand/shallow";
import { PLANNER_USER_WIDTH, siteOffsetX, usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import lsKeys from "../../../default_values/defaultKeys";
import { useNotificationStore } from "../../../../stores/notificationStore";

const UserRow = React.forwardRef(
  (
    {
      index,
      user,
      mouseDowned,
      mouseUped,
      weekendsHidden,
      deleteBookingsRequest,
      swapUsersRequest,
      firstPlannerDate,
      activeProjects,
      changeTooltip,
      topOffset,
      mouseMoved,
      selectBooking,
      moveBookingRequest,
      addBookingToCreation,
      filteredUsers,
      bookingFormat,
      teams,
      setIsSwappingUsers,
      profile,
      changeContextMenu,
      updateBookingsRequest,
      virtualProps,
      mouseClickDayView,
      tasks,
      weekendInfoRef,
      changePlannerRightClickRef,
    },
    ref
  ) => {
    const { projects } = useContext(PlannerContext);
    const [zoom] = useZoomStore((state) => [state.zoom], shallow);
    const [date] = useDateStore((state) => [state.date], shallow);
    const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
    const [dayViewBookings] = useBookingsCreatingStore((state) => [state.dayViewBookings], shallow);

    const [copyModeActive] = useCopyBookingsStore((state) => [state.copyModeActive], shallow);

    const [reloadedRow, setReloadedRow] = useState(false);
    const [rowBookings, setRowBookings] = useState(
      useBookingsStore.getState().bookings.filter((booking) => booking.user_id === user.id)
    );
    const [dateOpened, setDateOpened] = useState();
    const [bookingsDate, setBookingsDate] = useState();
    const bookingsRef = useRef([]);

    let days = GetDaysInZoom(zoom);

    const formatMapping = {
      0: 5,
      1: 10,
      2: 15,
    };

    const maxHeight = (usersMaxScheduleTime(user) / 60) * formatMapping[bookingFormat];

    useEffect(() => {
      bookingsRef.current = bookingsRef.current.slice(0, days);
    }, []);

    const columns = useMemo(() => {
      let userRow = [];

      if (zoom !== 1) {
        for (let i = 0; i < days / 7; i++) {
          userRow.push(
            <UserRowWeek
              mouseDowned={mouseDowned}
              user={user}
              startDate={OffsetDays(firstPlannerDate, i * 7)}
              mouseMoved={mouseMoved}
              mouseUped={mouseUped}
              weekendsHidden={weekendsHidden}
              changeTooltip={changeTooltip}
              filteredUsers={filteredUsers}
              key={`user-row-week-${user.id}-${i}`}
              isLastWeek={i + 1 === days / 7}
              isLastUser={index === filteredUsers.length - 1}
            />
          );
        }
      } else {
        for (let i = 0; i < 16; i++) {
          userRow.push(
            <DayViewRow
              user={user}
              mouseClickDayView={mouseClickDayView}
              hour={i}
              index={index}
              isLastUser={index === filteredUsers.length - 1}
            />
          );
        }
      }

      return userRow;
    }, [zoom, reloadedRow, weekendsHidden, projects, copyModeActive, filteredUsers, date]);

    const updateCells = () => {
      for (let i = 0; i < days; i++) {
        bookingsRef.current[i].updateBookings();
      }
    };

    const updateCellField = (fieldName, value, dateIndex) => {
      if (bookingsRef.current[dateIndex]) {
        switch (fieldName) {
          case "booking_duration":
            bookingsRef.current[dateIndex].updateBookingDuration(value);
            break;
          case "project_id":
            bookingsRef.current[dateIndex].updateProjectId(value);
            break;
        }
      }
    };

    const reloadRow = () => {
      setReloadedRow(!reloadedRow);
    };

    const updateBookings = () => {
      setRowBookings(useBookingsStore.getState().bookings.filter((booking) => booking.user_id === user.id));
    };

    React.useImperativeHandle(ref, () => {
      return {
        updateCells: updateCells,
        updateCellField: updateCellField,
        closeCreation: closeCreation,
        reloadRow: reloadRow,
        updateBookings: updateBookings,
        filterOutProject: filterOutProject,
      };
    });

    const closeCreation = (dateIndex) => {
      if (bookingsRef.current[dateIndex]) {
        bookingsRef.current[dateIndex].closeCreation();
      }
    };

    const filterOutProject = (filteredProjects) => {
      if (bookingsRef.current.length === 0) {
        setTimeout(() => {
          filterOutProject(filteredProjects);
        }, 500);
      }

      bookingsRef.current.forEach((cellRef) => {
        if (cellRef) {
          cellRef.filterOutProject(filteredProjects);
        }
      });
    };

    const moveBooking = (hash) => {
      const { offsetX, zoom, date, moveBookingRequest } = hash;

      const weekendsHidden = !(localStorage.getItem(lsKeys.WEEKENDS_HIDDEN_KEY) === "false");

      let toDate;

      const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(zoom);
      const dayWidth = getCellWidthForZoom(zoom);

      let dateIteration = 0;
      let calculatedOffsetX = 0;

      while (calculatedOffsetX < offsetX) {
        calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
        dateIteration++;
      }

      if (zoom === 1) {
        toDate = date;
      } else {
        toDate = OffsetDays(date, dateIteration - 1);
      }

      return moveBookingRequest({ ...hash, to_date: toDate });
    };

    const [collectedProps, drop] = useDrop(() => ({
      accept: "BOOKING",
      drop: (item, monitor) => {
        const currentBookings = useBookingsStore.getState().bookings;
        const scrollElement = $("#planner-scroll-list");
        const offsetX = Math.abs(monitor.getClientOffset().x + scrollElement.scrollLeft() - siteOffsetX());

        const booking = currentBookings.find(({ id }) => id === item.id);

        moveBooking({
          booking: booking,
          to_user: user.id,
          from_date: booking.date,
          from_user: booking.user_id,
          offsetX: offsetX,
          zoom: useZoomStore.getState().zoom,
          date: date,
          moveBookingRequest: moveBookingRequest,
          updateBookings: updateBookings,
        });
      },
    }));

    return (
      <Dropzone isDraggable={true} drop={drop}>
        <div
          id={"user-row-" + user.id}
          key={`${user.id}-${firstPlannerDate}`}
          ref={ref}
          style={{
            ...virtualProps,
            left: PLANNER_USER_WIDTH,
            zIndex: dateOpened ? 1 : "unset",
          }}
          className="user-row"
          onContextMenu={(e) => {
            e.preventDefault();

            if (useCopyBookingsStore.getState().copyBookingIds.length > 0) {
              changePlannerRightClickRef({
                open: true,
                posX: e.pageX,
                posY: e.pageY,
                userId: user.id,
                date: date,
                index: index,
              });
            }

            e.stopPropagation();
          }}
        >
          {columns}

          {zoom !== 1 && (
            <UserHoursLeft
              bookingsArray={rowBookings}
              schedule={user.schedule.map((schedule) => schedule[0] + schedule[2])}
              days={days}
              date={firstPlannerDate}
              weekendsHidden={weekendsHidden}
              topOffset={topOffset}
              user={user}
              selectBooking={selectBooking}
              addBookingToCreation={addBookingToCreation}
              userIndex={index}
              deleteBookingsRequest={deleteBookingsRequest}
              setBookingsDate={setBookingsDate}
              setDateOpened={setDateOpened}
              bookingFormat={bookingFormat}
              copyModeActive={copyModeActive}
              projects={projects}
              weekendInfoRef={weekendInfoRef}
              isLastWeek={index === filteredUsers.length - 1}
              isLastUser={user.id === filteredUsers[filteredUsers.length - 1]?.id}
            />
          )}

          {dateOpened && (
            <DayInfo
              bookings={bookingsDate}
              user={user}
              closeDayInfo={() => {
                setDateOpened(null);
              }}
              editBookings={(booking) => selectBooking(booking)}
              deleteBookingsRequest={(booking) => deleteBookingsRequest([booking])}
              OpenCreationMenu={() => addBookingToCreation(index, new Date(dateOpened))}
              date={dateOpened}
              maxHeight={maxHeight}
              weekendsHidden={weekendsHidden}
              plannerDate={firstPlannerDate}
            />
          )}

          {zoom === 1 &&
            dayViewBookings
              .filter((booking) => booking[0] === index)
              .map((booking) => <DayViewCreation index={booking[0]} hour={booking[1]} />)}

          <UserBookings
            bookingsArray={rowBookings}
            date={firstPlannerDate}
            topOffset={topOffset}
            weekendsHidden={weekendsHidden}
            maxHeight={maxHeight}
            selectBooking={selectBooking}
            activeProjects={activeProjects}
            changeTooltip={changeTooltip}
            // ref={(el) => (bookingsRef.current[0] = el)}
            bookingFormat={bookingFormat}
            teams={teams}
            changeContextMenu={changeContextMenu}
            updateBookingsRequest={updateBookingsRequest}
            user={user}
            mouseMoved={mouseMoved}
            mouseUped={mouseUped}
            mouseDowned={mouseDowned}
            tasks={tasks}
            weekendInfoRef={weekendInfoRef}
            index={index}
          />

          <UserBlockedSpace
            maxHeight={maxHeight}
            bookingFormat={bookingFormat}
            date={firstPlannerDate}
            weekendsHidden={weekendsHidden}
            schedule={user.schedule}
            topOffset={topOffset}
            mouseMoved={mouseMoved}
            mouseUped={mouseUped}
            mouseDowned={mouseDowned}
            filteredUsers={filteredUsers}
            user={user}
            mouseClickDayView={mouseClickDayView}
          />
        </div>
      </Dropzone>
    );
  }
);

export default React.memo(UserRow);
