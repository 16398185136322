import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelectedProjectStore } from "../../stores/planner";
import orderedProjects from "./lib/orderProjects";
import { TeambookReactSelect } from "../default_components/TeambookReactSelect";
import Api from "../../Api";
import { WhiteTooltip } from "../default_components/Tooltips";
import TeambookOptionsLine from "../default_components/TeambookOptionsLine";

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 2,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 8,
    width: 8,
    minWidth: 8,
  },
});

const colourStyles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
    marginBottom: "20px",
  }),
  control: (styles) => ({
    ...styles,
    height: "44px !important",
    background: "var(--background-1)",
  }),
  option: (styles, { data, isDisabled, isSelected, isFocused }) => {
    const color = data.color;

    return {
      ...styles,
      color: isDisabled ? "#ccc" : "var(--text-2)",
      cursor: isDisabled ? "not-allowed" : "pointer",
      textAlign: "left",
      display: "flex",
      alignItems: "center",
      background: isSelected
        ? "var(--background-2) !important"
        : isFocused
        ? "var(--stroke) !important"
        : "var(--background-1)",
      height: 50,

      ":before": {
        backgroundColor: color,
        borderRadius: 2,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 8,
        minHeight: 8,
        width: 8,
        minWidth: 8,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    // ...dot(),
    width: "240px",
    color: "var(--text-2)",
    caretColor: "var(--cursor-color)",
  }),
  // placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color),
    maxWidth: "unset",
    fontSize: 14,
    color: "var(--text-2) !important",
    fff: 11,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "4px 12px",
  }),
};

const ProjectSelect = ({
  projectsType,
  projects,
  creationUsers,
  selectDefaultProjects,
  selectTimeOffProjects,
  actualLogs = false,
}) => {
  const { t } = useTranslation();

  const { selectedProject, selectedTimeOff, setSelectedProject, setSelectedTimeOff } = useSelectedProjectStore();
  const [suggestedProjects, setSuggestedProjects] = useState([]);
  const [passedValueComparison, setPassedValueComparison] = useState([]);
  let projectsList = [];

  orderedProjects(projects).forEach((project) => {
    if (projectsType === "time_off" && project.kind === "time_off" && project.active) {
      projectsList.push(project);
    } else if (projectsType !== "time_off" && project.kind !== "time_off") {
      projectsList.push(project);
    }
  });

  let clientNames = projectsList.map((project) => project.client_name);

  clientNames = [...new Set(clientNames)]; // [ 'A', 'B']

  let options = clientNames.map((clientName) => {
    if (clientName) {
      return {
        label: clientName,
        options: projectsList.filter((project) => project.client_name === clientName),
      };
    } else {
      return {
        label: t("projects.no_client"),
        options: projectsList.filter((project) => project.client_name === null),
      };
    }
  });

  useEffect(() => {
    if (
      creationUsers.length > 0 &&
      (passedValueComparison[0] !== creationUsers[0] || creationUsers.length !== passedValueComparison.length)
    ) {
      setPassedValueComparison(creationUsers);
      Api.Bookings.suggestions(creationUsers, actualLogs).then((res) => setSuggestedProjects(res.data));
    }
  }, [creationUsers]);

  const calculateMenuHeight = () => {
    if (window.innerHeight < 600) {
      return 120;
    } else if (window.innerHeight < 750) {
      return 240;
    }
  };

  const filterOption = ({ label, data }, string) => {
    const projectName = label.toLocaleLowerCase();
    const projectCode = data.code.toLocaleLowerCase();
    const clientName = data.client_name?.toLocaleLowerCase();
    string = string.toLocaleLowerCase();

    // default search
    if (
      projectName.includes(string) ||
      (clientName !== undefined && clientName?.includes(string)) ||
      projectCode.includes(string)
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="project-selection">
        <TeambookOptionsLine
          options={[
            {
              text: t("planning.project"),
              value: "default",
              onClick: selectDefaultProjects,
            },
            {
              text: t("planning.time_off"),
              value: "time_off",
              onClick: selectTimeOffProjects,
            },
          ]}
          selectedOption={projectsType}
          united
        />

        <TeambookReactSelect
          isSearchable={true}
          id={"selectProject"}
          value={projectsType === "time_off" ? selectedTimeOff : selectedProject}
          label="Single select"
          options={[...options]}
          styles={colourStyles}
          maxMenuHeight={calculateMenuHeight()}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          filterOption={filterOption}
          onChange={(newValue) => {
            if (projectsType === "time_off") {
              setSelectedTimeOff(newValue);
            } else {
              setSelectedProject(newValue);
            }
          }}
        />

        {projectsType !== "time_off" && (
          <div className="suggested-projects">
            <p>Suggested:</p>

            {suggestedProjects.map((project) => (
              <WhiteTooltip title={`[${project.code}] ${project.name}`}>
                <div className="suggested-projects__project-block" onClick={() => setSelectedProject(project)}>
                  <div className="suggested-projects__circle" style={{ backgroundColor: project.color }}></div>
                  <p>{project.code.substring(0, 5)}</p>
                </div>
              </WhiteTooltip>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectSelect;
