import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Danger from "../default_images/danger_deletion.svg";
import TeambookBlueButton from "./TeambookBlueButton";
import TeambookOutlinedButton from "./TeambookOutlinedButton";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const WarningModal = ({ closeModal, onYes, onNo }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={650}
      className="tb-default-dialog warning-modal"
      open
      style={{ zIndex: "10000" }}
      onClose={closeModal}
    >
      <DialogTitle className="warning-modal__title">
        <p>{t("save_changes")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} alt={"close"} />
      </DialogTitle>
      <DialogContent className="warning-modal__content">
        <p style={{ whiteSpace: "pre-line" }}>{t("save_changes_text")}</p>
      </DialogContent>
      <DialogActions style={{ gap: 20 }}>
        <TeambookOutlinedButton onClick={onNo} text={t("exit_without_save")} />
        <TeambookBlueButton text={t("continue_editing")} onClick={closeModal} />
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
