import React from "react";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { ReactComponent as CloudUpload } from "../../default_images/upload_cloud.svg";

const IMG_WIDTH = 100;
const IMG_HEIGHT = 100;

const DropPhoto = ({
  setOpen,
  user,
  setUserImage,
  onChangePhoto = () => {},
  setUploadPhotoProps,
  getAllUsers = () => {},
  viewType,
}) => {
  const { t } = useTranslation();
  const [src, setSrc] = React.useState(null);
  const [croppedImage, setCroppedImage] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const [croppedImageUrl, setCroppedImageUrl] = React.useState(null);
  const [crop, setCrop] = React.useState({
    unit: "%",
    aspect: 1,
    width: 50,
  });

  const onSend = (e) => {
    e.preventDefault();

    if (viewType !== "create") {
      const formData = new FormData();
      formData.append("token", localStorage.getItem("Auth-Token"));
      formData.append("image", croppedImage["croppedImage"]);
      Api.Users.update_photo(formData, user.id).then(analyzeUpdateResponse);
      setOpen(false);
      setUserImage(croppedImageUrl);
    } else {
      setUploadPhotoProps();
    }
  };

  const analyzeUpdateResponse = (response) => {
    getAllUsers();
    onChangePhoto();
  };

  const imageRef = React.useRef(null);
  const fileUrl = React.useRef(null);

  const onSelectFile = (file) => {
    setFile(file[0]);

    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));

      reader.readAsDataURL(file[0]);
    }
  };
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, "newFile.jpeg");
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = IMG_WIDTH;
    canvas.height = IMG_HEIGHT;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      IMG_WIDTH,
      IMG_HEIGHT
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        setCroppedImageUrl(reader.result);
        dataURLtoFile(reader.result, "cropped.png");
      };
    }, "image/png");
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });

    setCroppedImage({ croppedImage: croppedImage });
  };
  return (
    <Dropzone onDrop={(acceptedFiles) => onSelectFile(acceptedFiles)} accept="image/*">
      {({ getRootProps, getInputProps }) => (
        <section>
          {!src && (
            <div className={"drag-zone"} {...getRootProps()}>
              <input {...getInputProps()} />
              <CloudUpload />
              <p>
                {t("profile.general_info.text_1")}
                <span> {t("profile.general_info.text_2")}</span>
                {t("profile.general_info.text_3")}
                <br /> <p style={{ marginTop: 5 }}>{t("profile.general_info.text_4")}</p>
              </p>
            </div>
          )}
          {src && (
            <ReactCrop
              circularCrop
              keepSelection
              className="react-crop"
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
          <div
            style={{
              justifyContent: "flex-end",
              margin: "0px 40px 30px 40px",
              gap: 30,
            }}
            className="row actions"
          >
            <TeambookOutlinedButton text={t("cancel")} onClick={() => setOpen(false)} />

            <TeambookBlueButton
              text={t("profile.general_info.set_as_profile")}
              disabled={croppedImage ? false : true}
              onClick={(e) => {
                if (setUploadPhotoProps && viewType === "create") {
                  setUserImage(croppedImageUrl);
                  setUploadPhotoProps({
                    croppedImage: croppedImage,
                    analyzeUpdateResponse: analyzeUpdateResponse,
                  });
                  setOpen(false);
                } else {
                  onSend(e);
                }
              }}
            />
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropPhoto;
