import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import Api from "../../../../../Api";
import DangerImage from "../../../../default_images/danger_deletion.svg";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

export default function DeleteHoliday({ onCloseSelectedModal, selectedHoliday, getHolidays }) {
  const { t } = useTranslation();

  const deleteHoliday = (id) => {
    Api.Integrations.Holidays.delete(id).then(() => {
      getHolidays();
    });
  };

  return (
    <Dialog open className="tb-default-dialog__delete " onClose={onCloseSelectedModal}>
      <DialogTitle style={{ alignSelf: "center", paddingBottom: 35 }}>
        <img src={DangerImage} alt={"danger"} />
        <TeambookIcon className="close-icon" name={icons.CROSS} onClick={onCloseSelectedModal} />
      </DialogTitle>
      <DialogContent>
        <p>{t("settings.time_off.delete_holiday")}</p>
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton
          id={"deleteHolidayButton"}
          onClick={() => {
            deleteHoliday(selectedHoliday);
            onCloseSelectedModal();
          }}
          text={t("delete")}
        />
      </DialogActions>
    </Dialog>
  );
}
