import React, { useEffect, useState } from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import Api from "../../../../Api";
import { DialogActions, DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Dialog from "@mui/material/Dialog";
import TeambookRadioButton from "../../../default_components/TeambookRadioButton";
import { TeambookTeamsPicker, TeambookUsersPicker } from "../../../default_components/TeambookDefaultPickers";

const EntraSyncBlock = () => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [entraConnected, setEntraConnected] = useState(!!account.entra_id_sync_integration);
  const [manageModalOpen, setManageModalOpen] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const [entraGroups, setEntraGroups] = useState([]);
  const [integrationType, setIntegrationType] = useState(account.entra_id_sync_integration?.sync_type);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (entraConnected) {
      Api.Integrations.Outlook.getEntraGroups().then((response) => {
        setEntraGroups(response.data);

        const selectedGroup = response.data.filter(
          (g) => g.entra_id === account.entra_id_sync_integration?.entra_group_id
        )[0];

        setSelectedGroup({
          value: selectedGroup.entra_id,
          label: selectedGroup.name,
        });
      });
    }
  }, [entraConnected]);

  const entraSyncUrl = () => {
    switch (process.env.REACT_APP_ENV) {
      case "production":
        return "https://web.teambookapp.com/api/auth/entra_sync_integration?tenant_id=" + tenantId;
      case "staging":
        return "https://web.teambooktest.com/api/auth/entra_sync_integration?tenant_id=" + tenantId;
      default:
        return "http://localhost:3001/api/auth/entra_sync_integration?tenant_id=" + tenantId;
    }
  };

  const connectToEntra = () => {
    Api.Account.get().then((response) => {
      if (response.data.entra_id_sync_integration) {
        setEntraConnected(true);
      } else {
        window.open(entraSyncUrl(), "_blank");
        checkIfEntraSignedUp();
      }
    });
  };

  const checkIfEntraSignedUp = () => {
    Api.Account.get().then((response) => {
      if (response.data.entra_id_sync_integration) {
        setEntraConnected(true);
      } else {
        setTimeout(checkIfEntraSignedUp, 1000);
      }
    });
  };

  const updateIntegration = () => {
    Api.Integrations.Outlook.updateEntraSync(integrationType, selectedGroup?.value);
  };

  const getGroupsOptions = () => {
    return entraGroups.map((group) => ({
      value: group.entra_id,
      label: group.name,
    }));
  };

  const deleteEntraSync = () => {
    Api.Integrations.Outlook.deleteEntraSync().then(() => {
      setEntraConnected(false);
    });
  };

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.ENTRA_ID_ICON} alt="entra id logo" />
      </div>

      <div className="integration-status">
        <p className={entraConnected ? "active" : "not_active"}>{entraConnected ? "Active" : "Not connected"}</p>
      </div>

      <TeambookOutlinedButton
        onClick={() => setManageModalOpen(true)}
        variant="contained"
        text={entraConnected ? t("settings.integration.manage") : t("settings.integration.connect")}
        style={{
          width: "171px",
        }}
      />

      <Dialog
        fullWidth={true}
        open={manageModalOpen}
        onClose={() => setManageModalOpen(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog azure-sync-dialog"
      >
        <DialogTitle id="add-user-dialog">
          <p>MS Entra ID</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setManageModalOpen(false)} />
        </DialogTitle>

        <DialogContent>
          {entraConnected ? (
            <div style={{ height: "200px" }}>
              <p style={{ marginBottom: 10 }}>Configure integration</p>

              <div className="azure-sync__content">
                <div className="azure-sync__content-line">
                  <TeambookRadioButton
                    checked={integrationType === "specific_group"}
                    onCheck={() => {
                      setIntegrationType("specific_group");
                    }}
                  />

                  <p>Specific Security Group</p>

                  <TeambookReactSelect
                    isSearchable={true}
                    options={getGroupsOptions()}
                    value={selectedGroup}
                    onChange={(group) => {
                      setSelectedGroup(group);
                    }}
                    height={44}
                    maxMenuHeight={120}
                    width={200}
                  />
                </div>

                <div className="azure-sync__content-line">
                  <TeambookRadioButton
                    checked={integrationType === "all_users"}
                    onCheck={() => {
                      setIntegrationType("all_users");
                    }}
                  />

                  <p>All Entra ID users</p>
                </div>

                <div className="azure-sync__content-line" style={{ marginBottom: 10 }}>
                  <TeambookRadioButton
                    checked={integrationType === "deactivated"}
                    onCheck={() => {
                      setIntegrationType("deactivated");
                    }}
                  />

                  <p>Turn OFF</p>
                </div>
              </div>

              <p style={{ fontSize: "16px" }}>Keep in mind that data is synced every 4 hours.</p>
            </div>
          ) : (
            <div style={{ marginBottom: 60 }}>
              <p>Please insert tenant id of your Azure Directory:</p>

              <TeambookTextForm
                fieldName={"Tenant ID"}
                onChange={setTenantId}
                fieldValue={tenantId}
                type={"text"}
                width={"400px"}
                placeholder={"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}
              />

              <TeambookBlueButton
                className="azure-sync__button"
                onClick={connectToEntra}
                variant="contained"
                text={t("submit")}
                style={{
                  position: "absolute",
                  right: 40,
                  bottom: 30,
                }}
              />
            </div>
          )}
        </DialogContent>

        {entraConnected && (
          <DialogActions>
            <TeambookOutlinedButton
              className="azure-sync__button"
              onClick={deleteEntraSync}
              variant="contained"
              text={t("delete")}
            />

            <TeambookBlueButton
              className="azure-sync__button"
              onClick={updateIntegration}
              variant="contained"
              text={t("save")}
            />
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default EntraSyncBlock;
