import React from "react";
import { TeambookIcon } from "../default_images/TeambookIcon";

const TeambookIconButton = ({ onClick, style, className, name, iconColor = "darkblue_new", tooltipTitle }) => {
  return (
    <button
      className={`teambook-icon-button ${className}`}
      onClick={onClick}
      style={{
        ...style,
      }}
    >
      <TeambookIcon color={iconColor} name={name} tooltipTitle={tooltipTitle} />
    </button>
  );
};

export default TeambookIconButton;
