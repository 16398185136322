import React, { useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import Cross from "../../default_images/cross.svg";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import DeleteBookingsRequest from "../requests/DeleteBookingsRequest";
import { useBookingsEditingStore, useBookingsStore, useDateStore, useZoomStore } from "../../../stores/planner";
import { PlannerContext } from "../context/PlannerContext";
import { shallow } from "zustand/shallow";
import { DialogTitle } from "@mui/material";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";

const RepetitionDeleteQuestionDialog = ({
  open,
  setRepetitionQuestionDialogOpened,
  setBookings,
  closeCreationWindow,
  setEditWindowOpened,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  setProjects,
  setActiveProjects,
  activeProjects,
  filteredUsers,
}) => {
  const { t } = useTranslation();
  const { projects } = useContext(PlannerContext);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  const [bookings] = useBookingsStore((state) => [state.bookings], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);

  const [bookingsEditing] = useBookingsEditingStore((state) => [state.bookingsEditing], shallow);

  const handleClose = () => {
    setRepetitionQuestionDialogOpened(false);
  };

  const handleNo = () => {
    DeleteBookingsRequest(
      bookingsEditing,
      date,
      zoom,
      setBookings,
      closeCreationWindow,
      bookings,
      setEditWindowOpened,
      false,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      setProjects,
      setActiveProjects,
      projects,
      activeProjects
    );

    setRepetitionQuestionDialogOpened(false);
  };

  const handleYes = () => {
    DeleteBookingsRequest(
      bookingsEditing,
      date,
      zoom,
      setBookings,
      closeCreationWindow,
      bookings,
      setEditWindowOpened,
      true,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      setProjects,
      setActiveProjects,
      projects,
      activeProjects
    );

    setRepetitionQuestionDialogOpened(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="deactivation-dialog"
      className="tb-default-dialog"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        <p>{t("delete")}</p>
        <img onClick={handleClose} src={Cross} />
      </DialogTitle>
      <DialogContent style={{ margin: "20px 0px" }}>
        <p> {t("planning.delete_all_repetitions")}</p>
      </DialogContent>
      <DialogActions style={{ gap: 20 }}>
        <TeambookOutlinedButton onClick={handleYes} text={t("yes")} />
        <TeambookBlueButton onClick={handleNo} text={t("no")} />
      </DialogActions>
    </Dialog>
  );
};

export default RepetitionDeleteQuestionDialog;
