import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";
import lsKeys from "../default_values/defaultKeys";
import { Link } from "react-router-dom";

export const ActualsSelect = ({ approvalPage, style }) => {
  const role = localStorage.getItem(lsKeys.ROLE);
  const { t } = useTranslation();

  return (
    ["admin", "planner"].includes(role) && (
      <div style={style} className={"planner-type-select__shown-component "}>
        <div
          style={{
            background: !approvalPage ? "var(--planner-select-view-button)" : "unset",
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          }}
          className="planner-type-select__dropdown-option"
          id={"switch-to-enter-log"}
        >
          <Link to={"/actuals/time_logging"} style={{ textDecoration: "none" }}>
            <TeambookIcon
              tooltipTitle={t("actuals.approval_button")}
              style={{ width: 22, height: 22 }}
              name={icons.LOGGING_TIME}
              color="darkblue_new"
            />
          </Link>
        </div>

        <div
          style={{
            background: approvalPage ? "var(--planner-select-view-button)" : "unset",
            borderLeft: "1px solid var(--stroke)",
            opacity: localStorage.getItem(lsKeys.THEME) === "dark" ? 0.75 : 1,
          }}
          className="planner-type-select__dropdown-option"
        >
          <Link to={"/actuals"} style={{ textDecoration: "none" }}>
            <TeambookIcon
              style={{ width: 22, height: 22 }}
              name={icons.APPROVING_TIME}
              tooltipTitle={t("actuals.approve")}
              color="darkblue_new"
            />
          </Link>
        </div>
      </div>
    )
  );
};
