import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";
import ReactSelect from "react-select";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";

export default function ClientsFeedback({ messages }) {
  const [comments, setComments] = useState({});
  const [statuses, setStatuses] = useState({});

  const statusOptions = [
    { value: "new", label: "New" },
    { value: "ignore", label: "Ignore" },
    { value: "answered", label: "Answered" },
    { value: "done", label: "Done" },
  ];

  useEffect(() => {
    messages.forEach((message) => {
      setComments((prev) => ({
        ...prev,
        [message.id]: message.comment,
      }));

      setStatuses((prev) => ({
        ...prev,
        [message.id]: message.status,
      }));
    });
  }, []);

  const saveReview = (id) => () => {
    Api.Administrators.update_feedback(localStorage.getItem("tb-admin-token"), id, statuses[id], comments[id]);
  };

  const updateStatus = (id, status) => {
    setStatuses((prev) => {
      return { ...prev, [id]: status };
    });
    Api.Administrators.update_feedback(localStorage.getItem("tb-admin-token"), id, status, comments[id]);
  };

  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "absolute",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <table
        style={{
          width: "99.5%",
          margin: "70px 5px",
          textAlign: "left",
        }}
        border="1px solid black"
      >
        <tr style={{ textAlign: "center" }}>
          <th style={{ width: "20%", padding: 10 }}>From</th>
          <th style={{ width: "40%", padding: 10 }}>Message</th>
          <th style={{ width: "10%", padding: 10 }}>Send at</th>
          <th style={{ width: "10%", padding: 10 }}>Status</th>
          <th style={{ width: "20%", padding: 10 }}>Comment</th>
        </tr>
        {messages.map((message) => {
          return (
            <tr>
              <td style={{ width: "20%", padding: 10 }}>
                <p style={{ margin: 0 }}> Account: {message.account?.name}</p>
                <p style={{ margin: 0 }}>{`User: ${message.user?.first_name} ${message.user?.last_name}`}</p>
                <p style={{ margin: 0 }}>Email: {message.user?.email}</p>
              </td>
              <td style={{ width: "40%", padding: 10 }}>{message.message}</td>
              <td style={{ width: "10%", padding: 10 }}>
                {DateTime.fromISO(message.created_at).toFormat("dd/MM/yy HH:mm")}
              </td>
              <td style={{ width: "10%", padding: 10 }}>
                <ReactSelect
                  value={statusOptions.find((s) => s.value === statuses[message.id])}
                  options={statusOptions}
                  onChange={(status) => {
                    updateStatus(message.id, status.value);
                  }}
                  height={38}
                />
              </td>
              <td style={{ width: "20%", padding: 10 }}>
                <input
                  type="text"
                  value={comments[message.id]}
                  onChange={(e) =>
                    setComments((prev) => ({
                      ...prev,
                      [message.id]: e.target.value,
                    }))
                  }
                />

                <TeambookOutlinedButton text={"Save"} onClick={saveReview(message.id)} />
              </td>
            </tr>
          );
        })}
      </table>

      <ControlPanel />
    </>
  );
}
