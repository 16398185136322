import betaImage from "../../../default_images/beta.png";
import { Switch } from "@mui/material";
import TeambookWhiteButton from "../../TeambookWhiteButton";
import Api from "../../../../Api";
import TeambookBlueButton from "../../TeambookBlueButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const OutlookTab = ({ open }) => {
  const { t } = useTranslation();

  const [outlookConnected, setOutlookConnected] = useState();
  const [eventsPush, setEventsPush] = useState(false);
  const [eventsPull, setEventsPull] = useState(false);

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  useEffect(() => {
    Api.Integrations.Outlook.Calendar.get().then((res) => {
      setOutlookConnected(res.data.connected);

      if (res.data.connected) {
        setEventsPush(res.data.settings.events_push);
        setEventsPull(res.data.settings.events_pull);
      }
    });
  }, []);

  const fetchOutlookData = () => {
    Api.Integrations.Outlook.Calendar.get().then((res) => {
      setOutlookConnected(res.data.connected);

      if (res.data.connected) {
        setEventsPush(res.data.settings.events_push);
        setEventsPull(res.data.settings.events_pull);
      } else {
        setTimeout(() => {
          fetchOutlookData();
        }, 3000);
      }
    });
  };

  const outlookURL = () => {
    let url;

    switch (process.env.REACT_APP_ENV) {
      case "production":
        url = "https://web.teambookapp.com/api/auth/azure_calendar_integration";
        break;
      case "staging":
        url = "https://web.teambooktest.com/api/auth/azure_calendar_integration";
        break;
      default:
        url = "http://localhost:3001/api/auth/azure_calendar_integration";
        break;
    }

    return url;
  };

  const changeIntegration = (type, value) => {
    Api.Integrations.Outlook.Calendar.updateSettings({ [type]: value }).then(() => {
      fetchOutlookData();

      setInAppNotification("Changes were saved!");
    });
  };

  if (!open) {
    return null;
  }

  if (!outlookConnected) {
    return (
      <div className="outlook-integration deactivated">
        <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
        <TeambookBlueButton
          text={"Connect to Outlook"}
          onClick={() => {
            fetchOutlookData();

            window.open(outlookURL(), "_blank");
          }}
        />
      </div>
    );
  }

  return (
    <div className="calendar-integration">
      <img src={betaImage} alt={"BETA IMAGE"} className="beta-image" />
      <div className="google-integration__row">
        <p>{t("settings.integration.outlook_calendar.push")}</p>
        <Switch
          id={"integrateOutlookPush"}
          checked={eventsPush}
          onChange={(event) => {
            if (event.target.checked) {
              changeIntegration("events_push", true);
            } else {
              changeIntegration("events_push", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">{t("settings.integration.outlook_calendar.push_description")}</p>

      <div className="google-integration__row">
        <p title={t("settings.integration.outlook_calendar.pull_details")}>
          {t("settings.integration.outlook_calendar.pull")}
        </p>
        <Switch
          id={"integrateOutlookPull"}
          checked={eventsPull}
          onChange={(event) => {
            if (event.target.checked) {
              changeIntegration("events_pull", true);
            } else {
              changeIntegration("events_pull", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="helper-text">
        {t("settings.integration.outlook_calendar.pull_description_1")} <br />{" "}
        {t("settings.integration.outlook_calendar.pull_description_2")}
      </p>

      <TeambookWhiteButton
        text={t("disconnect")}
        onClick={() => {
          confirm(t("settings.integration.outlook_calendar.delete")) &&
            Api.Integrations.Outlook.Calendar.delete().then(() => {
              setOutlookConnected(false);
            });
        }}
        className="disconnect-button"
      />
    </div>
  );
};

export default OutlookTab;
