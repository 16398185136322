import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const CreditCardForm = () => {
  const { t } = useTranslation();
  const cardRef = useRef(null);
  const emptySrc = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

  return (
    <form id="cardForm">
      <div className="panel">
        {/* <header className="panel__header">
          <h1>Card Payment</h1>
        </header> */}
        <div className="panel__content">
          <div className="textfield--float-label" style={{ width: "100%", position: "relative" }}>
            <label className="hosted-field--label" htmlFor="card-number">
              Card Number
            </label>
            <div id="card-number" className="hosted-field" />
            <img
              style={{
                position: "absolute",
                bottom: 22,
                right: 10,
                width: 24,
                height: 24,
              }}
              src={emptySrc}
              id="card-logo"
            />
          </div>

          <div style={{ width: "100%" }} className="textfield--float-label">
            <label className="hosted-field--label" htmlFor="cardholder-name">
              Cardholder Name
            </label>
            <input ref={cardRef} id="cardholder-name" className="hosted-field" placeholder={"John Smith"} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: 160 }} className="textfield--float-label">
              <label className="hosted-field--label" htmlFor="expiration-date">
                <span className="icon" />
                Expiration Date
              </label>
              <div id="expiration-date" className="hosted-field" />
            </div>
            <div style={{ width: 120, marginLeft: "auto" }} className="textfield--float-label">
              <label className="hosted-field--label" htmlFor="cvv">
                <span className="icon" />
                CVC
              </label>
              <div id="cvv" className="hosted-field" />
            </div>
          </div>
        </div>

        <footer className="panel__footer">
          <button className="pay-button">{t("continue")}</button>
        </footer>
      </div>
    </form>
  );
};

export default CreditCardForm;
