import React from "react";
import Button from "@mui/material/Button";

const TeambookOutlinedButton = React.forwardRef(
  ({ text, htmlText, style, onClick, color, className, disabled, size = "medium" }, ref) => {
    const buttonSizes = {
      small: { minHeight: "32px", fontSize: "12px", padding: "4px 8px" },
      medium: { minHeight: "40px", fontSize: "14px", padding: "8px 14px" },
    };
    return (
      <Button
        ref={ref}
        style={{
          ...buttonSizes[size],
          fontFamily: "Montserrat",
          ...style,
        }}
        variant="contained"
        onClick={onClick}
        color={color}
        className={`teambook-outlined-button ${className}`}
        disabled={disabled}
      >
        {htmlText && <>{htmlText}</>}
        {text && <p>{text}</p>}
      </Button>
    );
  }
);

export default TeambookOutlinedButton;
