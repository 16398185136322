import React from "react";

const TeambookRedButton = ({ text, onClick, style, size = "medium", className }) => {
  const buttonSizes = {
    small: { minHeight: "32px", fontSize: "12px", padding: "4px 8px" },
    medium: { minHeight: "40px", fontSize: "14px", padding: "8px 14px" },
  };

  return (
    <button
      className={`teambook-red-button ${className}`}
      onClick={onClick}
      style={{
        ...buttonSizes[size],
        ...style,
      }}
    >
      <p>{text}</p>
    </button>
  );
};

export default TeambookRedButton;
