import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./dashboard.scss";
import { useTranslation } from "react-i18next";
import ExportDataDialog from "../planner/modals/ExportDataDialog";
import { useNavigate } from "react-router-dom";
import FilterList from "@mui/icons-material/FilterList";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/es";
import TeambookFilterComponent from "../default_components/teambook_filter/TeambookFilterComponent";
import { TeambookReactSelect } from "../default_components/TeambookReactSelect";
import TeambookDatePicker from "../default_components/teambook_date_selector/DatePicker";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import Menu from "@mui/material/Menu";
import { shallow } from "zustand/shallow";
import { useDashboardStore } from "../../stores/dashboard";
import { AnalyticsSelect } from "./sub_components/AnalyticsSelect";
import { TodayButton } from "../default_components/TodayButton";

const DashboardControlBar = ({
  dateRange,
  setDateRange,
  zoom,
  setZoom,
  allTeams,
  version,
  setMobileMenuIsOpen,
  mobileMenuIsOpen,
  isLoaded,
  projects,
  users,
  tags,
  clients,
  view,
  setView,
  dashboardViews,
  dateRangeForType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [exportAnchor, setExportAnchor] = useState();
  const [exportMenuOpened, setExportMenuOpened] = useState(false);
  const [exportBookingsDialogOpen, setExportBookingsDialogOpen] = useState(false);
  const [exportType, setExportType] = useState("bookings");

  const [setReportingType, setReportingSplitBy] = useDashboardStore(
    (state) => [state.setReportingType, state.setReportingSplitBy],
    shallow
  );

  const moveToReporting = (type = "default") => {
    let split;

    switch (type) {
      case "actuals":
        split = "actuals_project";
        break;
      case "comparison":
        split = "comparison_project";
        break;
      case "capacity":
        split = "capacity_user";
        break;
      default:
        split = "project";
        break;
    }

    setReportingType(type);
    setReportingSplitBy(split);

    navigate("/dashboard/reporting");
  };

  const openExportDropdown = (event) => {
    setExportAnchor(event.currentTarget);
    setExportMenuOpened(true);
  };

  const openExportModal = (type) => {
    setExportBookingsDialogOpen(true);
    setExportType(type);
    setExportMenuOpened(false);
  };

  return (
    <div className="row control-bar dashboard__control-bar">
      <div className="dashboard__control-bar_wrapper">
        <div className="dashboard__control-bar-datepicker">
          <TodayButton
            setDate={(date) => {
              setDateRange({ start: date, end: dateRangeForType(date, zoom).end });
            }}
          />

          <TeambookDatePicker
            from={dateRange.start}
            to={dateRange.end}
            formatStart={+zoom === 1 ? "LLL yyyy" : "d LLL"}
            formatEnd="d LLL yyyy"
            setRange={setZoom}
            range={zoom}
            setFrom={setDateRange}
            height={40}
            width={+zoom !== 1 || (+zoom !== 1 && view === "capacity") ? 220 : 142}
            disabledRanges={view === "capacity" ? ["custom", "month", "3_months"] : ["1_year", "2_years", "6_months"]}
            arrowColor="darkblue_new"
          />
        </div>

        <div className="planner-control__filter-text-field">
          <TeambookFilterComponent
            users={users}
            projects={projects}
            tags={tags}
            teams={allTeams}
            clients={clients}
            className="dashboard__filter"
            filterPage={"dashboard"}
          />
        </div>

        {isLoaded && (
          <ExportDataDialog
            open={exportBookingsDialogOpen}
            handleClose={() => {
              setExportBookingsDialogOpen(false);
            }}
            view={view}
            date={dateRange}
            range={zoom}
            exportType={exportType}
          />
        )}

        <div className="dashboard-control-bar__right-side">
          <TeambookReactSelect
            width={200}
            value={dashboardViews.find(({ value }) => value === view)}
            onChange={({ value }) => {
              setView(value);
              if (value === "capacity") {
                setZoom(3);
              } else if (view === "capacity" && value !== "capacity") {
                setZoom(1);
              }
            }}
            options={dashboardViews}
            height={40}
          />

          <div style={{ display: "flex", gap: 40 }}>
            <div className="dashboard__button-wrapper" onClick={openExportDropdown}>
              <TeambookIcon
                id="exportBookings"
                alt={"export-arrow"}
                tooltipTitle={t("dashboard.export_data")}
                style={{
                  width: "24px",
                  height: "24px",
                }}
                name={icons.EXPORT_ARROW}
                color="darkblue_new"
              />
            </div>

            <AnalyticsSelect
              moveToReporting={() =>
                moveToReporting(["default", "capacity", "actuals"].includes(view) ? view : "comparison")
              }
              currentPage="analytics"
            />
          </div>
        </div>

        <FilterList
          onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)}
          style={{
            height: "24px",
            width: "24px",
            color: "var(--text-2)",
            display: version !== "mobile" && "none",
          }}
        />

        <Menu
          id="dashboard-export-menu"
          className="header-menu help-menu dashboard__export-menu"
          anchorEl={exportAnchor}
          keepMounted
          open={exportMenuOpened}
          onClose={() => setExportMenuOpened(false)}
          style={{ marginTop: 12 }}
        >
          <div className="header-menu__item-content" onClick={() => openExportModal("bookings")}>
            <p>{t("dashboard.bookings")}</p>
          </div>

          <div className="header-menu__item-content" onClick={() => openExportModal("capacity")}>
            <p>{t("dashboard.capacity")}</p>
          </div>

          <div className="header-menu__item-content" onClick={() => openExportModal("actuals")}>
            <p>{t("dashboard.actual_logs")}</p>
          </div>

          <div className="header-menu__item-content" onClick={() => openExportModal("all")}>
            <p>{t("dashboard.bookings_and_actual_logs")}</p>
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default DashboardControlBar;
