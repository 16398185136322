import React, { useEffect, useRef, useState } from "react";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { useTranslation } from "react-i18next";
import UploadProjectsDialog from "./default_components/UploadProjectsDialog";
import ExceedProjectsDialog from "./default_components/ExceedProjectsDialog";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { ClickAwayListener } from "@mui/base";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import lsKeys from "../../default_values/defaultKeys";
import { shallow } from "zustand/shallow";
import { useProjectsVisibilityColumnsStore } from "../../../stores/projects";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useAccountStore } from "../../../stores/accountStore";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import Api from "../../../Api";
import { availableColumns, defaultData } from "./projects_table/lib/helperValues";

const ProjectsFilterRow = ({
  setArchivedSelect,
  setClientsOpen,
  archivedSelect,
  openCreation,
  openTimeOffCreation,
  getActiveProjects,
  exceedWindowOpened,
  setExceedWindowOpened,
  activeProjects,
  deactivatedProjects,
  getAllClients,
  clients,
}) => {
  const { t } = useTranslation();
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false);
  const [uploadDropdownShow, setUploadDropdownShow] = useState(false);
  const [columnsSettingsOpened, setColumnsSettingsOpened] = useState(false);
  const [managers, setManagers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const columnsAnchorRef = useRef(null);

  const [account] = useAccountStore((state) => [state.account], shallow);

  const { custom_project_fields } = account;

  const [columnsVisibility, setColumnsVisibility] = useProjectsVisibilityColumnsStore(
    (state) => [state.columnsVisibility, state.setColumnsVisibility],
    shallow
  );

  const selectOptions = [
    { value: "Active", label: t("projects.active") },
    { value: "Archived", label: t("projects.deactivated") },
  ];

  useEffect(() => {
    if (localStorage.getItem(lsKeys.PROJECTS_COLUMNS_DISPLAY)) {
      setColumnsVisibility(JSON.parse(localStorage.getItem(lsKeys.PROJECTS_COLUMNS_DISPLAY)));
    } else {
      setColumnsVisibility(defaultData);
    }
  }, []);

  useEffect(() => {
    let availableProjects = archivedSelect === "Active" ? activeProjects : deactivatedProjects;

    Api.Projects.managers(availableProjects.map(({ id }) => id)).then((response) => {
      setManagers(response.data);
    });

    Api.Projects.business_units(availableProjects.map(({ id }) => id)).then((response) => {
      setBusinessUnits(response.data.business_units);
    });
  }, [archivedSelect]);

  useEffect(() => {
    localStorage.setItem(lsKeys.PROJECTS_COLUMNS_DISPLAY, JSON.stringify(columnsVisibility));
  }, [columnsVisibility]);

  const getFilterClients = () => {
    const clientsWithBookings = [];

    let availableProjects = archivedSelect === "Active" ? activeProjects : deactivatedProjects;

    availableProjects.forEach((pr) => {
      if (pr.client_id && !clientsWithBookings.includes(pr.client_id)) {
        clientsWithBookings.push(pr.client_id);
      }
    });

    return clients.filter((client) => clientsWithBookings.includes(client.id));
  };

  const switchDropdownShow = () => {
    setUploadDropdownShow(!uploadDropdownShow);
  };

  const closeDropdowns = () => {
    setUploadDropdownShow(false);
    setColumnsSettingsOpened(false);
  };

  const handleChangeArchived = (newValue) => {
    setArchivedSelect(newValue.value);
  };

  const openUploadProjectsWindow = () => {
    isLimitReached ? setExceedWindowOpened(true) : setUploadDialogOpened(true);
  };

  const _openTimeOffCreation = () => {
    openTimeOffCreation();
  };

  const switchColumnsVisibility = (field) => {
    let oldObject = { ...columnsVisibility };

    if (columnsVisibility[field] !== undefined) {
      oldObject[field] = !columnsVisibility[field];
    } else {
      oldObject[field] = !defaultData[field];
    }

    setColumnsVisibility(oldObject);
  };

  const isLimitReached = account?.active_projects >= account?.subscription?.projects_available;

  return (
    <div className="filter-row">
      <div className="filter-row__left-side">
        <div className="planner-control__filter-text-field users-page-filter mobile_hidden">
          <TeambookFilterComponent
            filterPage="projectsPage"
            users={[]}
            teams={[]}
            tags={[]}
            businessUnits={businessUnits}
            clients={getFilterClients()}
            managers={managers}
            projects={archivedSelect === "Active" ? activeProjects : deactivatedProjects}
          />
        </div>
        <div className="deactivate-switch">
          {selectOptions.map((value) => (
            <div
              onClick={() => handleChangeArchived(value)}
              className={`${
                value.value === archivedSelect ? "active" : ""
              } projects__active-entity-switch__option pointer`}
            >
              <p>{value.label}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="filter-row__right-side">
        <div className="manage-clients-button disabled mobile_hidden" onClick={() => setClientsOpen(true)}>
          <div className="filter-row__button-caption ">
            <TeambookIcon name={icons.TASKS} color="darkblue_new" />
          </div>
        </div>

        <div
          className="manage-clients-button mobile_hidden"
          title={t("projects.manage_clients")}
          onClick={() => setClientsOpen(true)}
        >
          <div className="filter-row__button-caption">
            <TeambookIcon name={icons.PLACE} color="darkblue_new" tooltipTitle={t("projects.clients")} />
          </div>
        </div>

        <div
          style={{ marginRight: 28 }}
          ref={columnsAnchorRef}
          className="columns-settings-button mobile_hidden"
          onClick={() => {
            setColumnsSettingsOpened(true);
          }}
        >
          <TeambookIcon
            id="settings"
            classname="planner-control-panel__setting-icon"
            name={icons.EYE_OPENED}
            tooltipTitle={t("planning.control_panel.visual_settings")}
            color="darkblue_new"
          />

          {columnsSettingsOpened && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div
                style={{
                  left: columnsAnchorRef.current.getBoundingClientRect().x - 50,
                  top: columnsAnchorRef.current.getBoundingClientRect().y - 15,
                }}
                className="control-bar__dropdown-fields"
              >
                {[...availableColumns, ...custom_project_fields.filter((field) => field)].map((key) => (
                  <div className="columns-selection-option" key={`columns-setting-${key}`}>
                    <TeambookCheckbox
                      checked={columnsVisibility[key] || false}
                      onChange={() => switchColumnsVisibility(key)}
                    />
                    <p onClick={() => switchColumnsVisibility(key)}>
                      {custom_project_fields.find((field) => field === key)
                        ? key
                        : t(`projects.selected_fields.${key}`)}
                    </p>
                  </div>
                ))}
              </div>
            </ClickAwayListener>
          )}
        </div>

        <div style={{ display: "flex", position: "relative" }}>
          {isLimitReached ? (
            <div>
              <WhiteTooltip title={t("projects.no_more_projects")}>
                <TeambookBlueButton
                  style={{ backgroundColor: "var(--button-bg-disabled)", color: "var(--button-text-disabled)" }}
                  alt="mobile-add"
                  className="mobile_hidden filter-row__add-project-button "
                  onClick={() => setExceedWindowOpened(true)}
                  variant="contained"
                  borderRadius="7px 0px 0px 7px"
                  disabledAnimations
                  text={
                    <div className="filter-row__add-user-button_text">
                      <TeambookIcon color="grouped_planner_add" name={icons.PROJECT_PLUS} />
                      <p>{t("projects.create_project")}</p>
                    </div>
                  }
                />
              </WhiteTooltip>
              {/*<img*/}
              {/*  alt="mobile-add"*/}
              {/*  className="desktop_hidden filter-row__mobile-button"*/}
              {/*  src={AddPlus}*/}
              {/*  onClick={() => setExceedWindowOpened(true)}*/}
              {/*/>*/}
            </div>
          ) : (
            <div>
              <TeambookBlueButton
                alt="mobile-add"
                className="mobile_hidden filter-row__add-project-button"
                onClick={openCreation}
                variant="contained"
                borderRadius="4px 0px 0px 4px !important"
                text={
                  <div className="filter-row__add-user-button_text">
                    <TeambookIcon color="white" name={icons.PROJECT_PLUS} />
                    <p>{t("projects.create_project")}</p>
                  </div>
                }
              />
              {/*<img*/}
              {/*  alt="mobile-add"*/}
              {/*  className="desktop_hidden filter-row__mobile-button"*/}
              {/*  src={AddPlus}*/}
              {/*  onClick={openCreation}*/}
              {/*/>*/}
            </div>
          )}

          <div
            style={{
              backgroundColor: isLimitReached
                ? "var(--button-bg-disabled)"
                : isHovered
                ? "var(--primary-btn-hover-bg)"
                : "var(--accent-dark)",
            }}
            onMouseEnter={() => !isLimitReached && setIsHovered(true)}
            onMouseLeave={() => !isLimitReached && setIsHovered(false)}
            onClick={() => {
              if (!isLimitReached) {
                switchDropdownShow();
              }
            }}
            className="create-dropdown-trigger mobile_hidden"
          >
            <span
              className="button-separator"
              style={{
                backgroundColor: isLimitReached ? "var(--background-2)" : "",
              }}
            />
            <TeambookIcon
              style={{ rotate: uploadDropdownShow ? "180deg" : "0deg" }}
              color={isLimitReached ? "grouped_planner_add" : "default"}
              name={isLimitReached ? icons.ARROW_DOWN : icons.BUTTON_ARROW_DOWN}
            />
          </div>

          {uploadDropdownShow && (
            <ClickAwayListener onClickAway={closeDropdowns}>
              <div className="control-bar__dropdown">
                <div className="control-bar__dropdown__option" onClick={() => _openTimeOffCreation()}>
                  <TeambookIcon name={icons.TIME_OFF_TICKET} />
                  <p>{t("settings.time_off.create_time_off")}</p>
                </div>

                <div className="control-bar__dropdown__option" onClick={() => openUploadProjectsWindow()}>
                  <TeambookIcon name={icons.UPLOAD} color="darkblue_new" />
                  <p>{t("projects.upload_projects")}</p>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>

      <UploadProjectsDialog
        open={uploadDialogOpened}
        handleClose={() => {
          setUploadDialogOpened(false);
        }}
        getActiveProjects={getActiveProjects}
        getAllClients={getAllClients}
      />

      <ExceedProjectsDialog
        open={exceedWindowOpened}
        handleClose={() => {
          setExceedWindowOpened(false);
        }}
      />
    </div>
  );
};

export default ProjectsFilterRow;
