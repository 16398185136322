import React from "react";
import "../capacity_planning.scss";
import { DateTime } from "luxon";
import { getWorkingDays, monthsInDateRanges } from "../../../lib/DateUtils";

const DatesRow = ({ date, selectedViewType, type = "planning" }) => {
  const monthsInYear = monthsInDateRanges(date, date.plus({ months: selectedViewType - 1 }));
  const monthsCount = monthsInYear.reduce((cur, prev) => cur + prev, 0);
  let month = 0;

  return (
    <>
      <div
        className="users-block__dates-row first-row"
        style={{
          borderBottom: "none",
        }}
      >
        <div className="left-side" />

        <div style={{ minWidth: monthsInYear.reduce((pr, cur) => pr + cur, 0) * 80 }} className="flex right-side">
          <div className="date-row__year-row">
            <div style={{ width: 260, minWidth: 260, maxWidth: 260 }} />

            {Array.from({ length: date.plus({ months: selectedViewType }).year - date.year + 1 }).map(
              (_, index, yearsArray) => {
                const year = date.plus({ year: index }).year;

                return Array.from({ length: monthsInYear[index] }).map((_, j) => {
                  const isEven = (num) => num % 2 === 0;
                  month++;

                  const isLastCell = month === monthsCount;

                  const isBordered = () => {
                    if (isLastCell) {
                      return "1px solid var(--stroke)";
                    } else if (j === monthsInYear[index] - 1) {
                      return "1px solid var(--text-12)";
                    }
                    return "1px solid transparent";
                  };

                  return (
                    <div
                      style={{
                        borderRight: isBordered(),
                        borderBottom: "1px solid transparent",
                        justifyContent: isEven(monthsInYear[index]) ? "unset" : "center",
                        textAlign: isEven(monthsInYear[index]) ? "unset" : "center",
                        borderTopRightRadius: isLastCell ? 8 : 0,
                      }}
                      className="table-cell"
                    >
                      <p style={{ marginLeft: isEven(monthsInYear[index]) ? "-17px" : "0px" }}>
                        {j === Math.floor(monthsInYear[index] / 2) && year}
                      </p>
                    </div>
                  );
                });
              }
            )}
          </div>
        </div>
      </div>

      {type === "reporting" ? (
        <div
          className="users-block__dates-row"
          style={{
            top: "60px",
            paddingleft: 30,
            height: 31,
          }}
        >
          <div
            style={{
              borderTop: type === "reporting" && "1px solid #c8d2e6",
              height: type === "reporting" ? 31 : 60,
              background: "#fff",
            }}
            className="left-side"
          >
            <div />
          </div>
          <div
            style={{
              paddingLeft: 30,
              height: type === "reporting" ? 31 : 60,
            }}
            className="flex right-side"
          >
            {[...Array(selectedViewType)].map((x, i) => (
              <div
                style={{
                  height: 31,
                  background: "red",
                }}
                className="table-cell"
              >
                <p>{date.plus({ months: i }).toFormat("LLL").toUpperCase()}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className="users-block__dates-row is-capacity-months"
          style={{
            top: 34,
          }}
        >
          <div
            className="left-side second-row"
            style={{
              height: 36,
              width: 260,
              minWidth: 260,
              maxWidth: 260,
              borderRight: "1px solid var(--stroke)",
            }}
          >
            <div />
          </div>
          <div style={{ height: 36 }} className="flex right-side">
            {[...Array(selectedViewType)].map((x, i, arr) => (
              <div
                className={`table-cell ${
                  date.plus({ months: i }).startOf("month").ts === DateTime.now().startOf("month").ts && "current-month"
                }`}
                style={{
                  height: 36,
                  borderRight:
                    arr.length - 1 === i
                      ? "1px solid var(--stroke)"
                      : date.plus({ months: i }).month === 12
                      ? "1px solid var(--text-12)"
                      : "1px solid var(--stroke)",
                  borderTop: "1px solid var(--stroke)",
                  position: "relative",
                }}
              >
                <div>
                  <p>{date.plus({ months: i }).toFormat("LLL")}</p>
                  <p style={{ fontSize: 11 }}>
                    ({getWorkingDays(date.year, date.plus({ months: i }).month)}
                    d)
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DatesRow;
