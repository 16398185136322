import { useBookingsStore, useDateStore, useShiftPressedStore, useZoomStore } from "../../../../stores/planner";
import { getCellWidthForZoom, getNumberOfDays, OffsetDays } from "../../../../lib/DateUtils";
import { usersMaxScheduleTime } from "../../../../lib/PlannerUtils";
import $ from "jquery";

class PlannerActions {
  #userIndexStart = null;
  #userDateStart = null;
  #clickStartX = null;
  #clickStartY = null;
  #formatMapping = {
    0: 5,
    1: 10,
    2: 15,
  };
  #shiftCreation = false;
  #userIndexEnd = null;
  #userDateEnd = null;
  #id = Date.now() % 10000;
  #multipleCreation = false;

  mouseUped(offsetX, offsetY, users, e) {}

  mouseMoved(offsetX, offsetY, users) {}

  mouseDowned(offsetX, offsetY, users) {}

  get formatMapping() {
    return this.#formatMapping;
  }

  get userIndexStart() {
    return this.#userIndexStart;
  }
  set userIndexStart(userIndex) {
    this.#userIndexStart = userIndex;
  }

  get userDateStart() {
    return this.#userDateStart;
  }
  set userDateStart(dateStart) {
    this.#userDateStart = dateStart;
  }

  get clickStartX() {
    return this.#clickStartX;
  }
  set clickStartX(position) {
    this.#clickStartX = position;
  }

  get clickStartY() {
    return this.#clickStartY;
  }
  set clickStartY(position) {
    this.#clickStartY = position;
  }

  get shiftCreation() {
    return this.#shiftCreation;
  }
  set shiftCreation(newShiftCreation) {
    this.#shiftCreation = newShiftCreation;
  }

  get userIndexEnd() {
    return this.#userIndexEnd;
  }
  set userIndexEnd(newIndex) {
    this.#userIndexEnd = newIndex;
  }

  get userDateEnd() {
    return this.#userDateEnd;
  }
  set userDateEnd(newDate) {
    this.#userDateEnd = newDate;
  }

  get multipleCreation() {
    return this.#multipleCreation;
  }
  set multipleCreation(newValue) {
    this.#multipleCreation = newValue;
  }

  get id() {
    return this.#id;
  }

  getBookingById = (id) => useBookingsStore.getState().bookings.find((b) => b.id === id);

  //TODO: weekendsHidden into storage
  //TODO: bookingFormat into storage
  calculateUserDateStart = (offsetX, offsetY, users, weekendsHidden, bookingFormat) => {
    const weekendWidth = weekendsHidden ? 10 : getCellWidthForZoom(useZoomStore.getState().zoom);
    const dayWidth = getCellWidthForZoom(useZoomStore.getState().zoom);
    let currentHeight = 0;
    const userHeights = users.map(
      (user) => (usersMaxScheduleTime(user) / 60) * this.#formatMapping[bookingFormat] + 17
    );

    userHeights.forEach((userHeight, index) => {
      if (
        currentHeight < offsetY + $("#planner-scroll-list").scrollTop() &&
        currentHeight + userHeight > offsetY + $("#planner-scroll-list").scrollTop()
      ) {
        this.#userIndexStart = index;

        let calculatedOffsetX = 0;
        let dateIteration = 0;

        while (calculatedOffsetX < offsetX + $("#planner-scroll-list").scrollLeft()) {
          calculatedOffsetX += dateIteration % 7 < 5 ? dayWidth : weekendWidth;
          dateIteration++;
        }

        this.#userDateStart = OffsetDays(useDateStore.getState().date, dateIteration - 1);
      }

      currentHeight += userHeight;
    });
  };

  redrawCreationBookings = (weekendsHidden) => {
    let creationBookings = [];
    this.shiftCreation = false;

    for (let i = this.userIndexStart; i <= this.userIndexEnd; i++) {
      let dateDiff = getNumberOfDays(this.userDateStart, this.userDateEnd);

      for (let j = 0; j <= dateDiff; j++) {
        if (j > 0 || i > this.userIndexStart) {
          this.shiftCreation = true;
        }

        if (
          (weekendsHidden && OffsetDays(this.userDateStart, j).getDay() === 0) ||
          (weekendsHidden && OffsetDays(this.userDateStart, j).getDay() === 6)
        ) {
          continue;
        }

        creationBookings.push([i, OffsetDays(this.userDateStart, j)]);
      }
    }

    return creationBookings;
  };
}

export default PlannerActions;
